import { atom, selector, useRecoilState, useRecoilValue } from 'recoil';
import { localStorageEffect } from './utils';
export const progressAtom = atom({
	key: 'progress', // unique ID (with respect to other atoms/selectors)
	default: 0, // default value (aka initial value)
});
const planAtom = atom({
	key: 'plan',
	default: { plan: '', cost: 0 },
	effects: [localStorageEffect('plan')],
});
const termsAtom = atom({
	key: 'terms',
	default: false,
});
const saveData = atom({
	key: 'saveData',
	default: false,
});
const serverDataAtom = atom({
	key: 'serverData',
	default: {},
	effects: [localStorageEffect('serverData')],
});
const verifyAtom = atom({
	key: 'verify',
	default: false,
});
const verifyFullyDataAtom = atom({
	key: 'verifyFully',
	default: false,
});

export const useTerms = () => {
	const [terms, setTerms] = useRecoilState(termsAtom);
	const [verfyFull, setVerifyFull] = useRecoilState(verifyFullyDataAtom);

	const [verify, setVerify] = useRecoilState(verifyAtom);
	return {
		terms,
		setTerms,
		verify,
		setVerify,
		verfyFull,
		setVerifyFull,
	};
};
export const userDataAtom = atom({
	key: 'userData', // unique ID (with respect to other atoms/selectors)
	default: {
		email: '',
		father_surname: '',
		mother_surname: '',
		born: '',
		name: '',
		curp: '',
		rfc: 'AAAA000000',
		status: '',
		celphone: '',
		gender: '',
	}, // default value (aka initial value)
	effects: [localStorageEffect('userData')],
});
export const errorsAtom = atom({
	key: 'errorsData',
	default: {},
});
const hasErrorSelector = selector({
	key: 'hasErrorSelector',
	get: ({ get }) => {
		const errors = get(errorsAtom);
		let hasError = false;
		Object.keys(errors).forEach(err => {
			if (Boolean(errors[err])) hasError = true;
		});
		return hasError;
	},
});

export const useProgress = () => {
	const [progress, setProgress] = useRecoilState(progressAtom);
	return { progress, setProgress };
};
export const usePlan = () => {
	const [selectedPlan, setSelectedPlan] = useRecoilState(planAtom);
	// if (key === 'plan') {
	//     copyOfUserData.cost = value === 'base' ? 180 : 660;
	// }
	return { selectedPlan, setSelectedPlan };
};
export const useUserData = () => {
	const [serverData, setServerData] = useRecoilState(serverDataAtom);
	const [userData, setUserData] = useRecoilState(userDataAtom);
	const [pressSaved, setSaveData] = useRecoilState(saveData);
	const [errors, setErrors] = useRecoilState(errorsAtom);
	const hasError = useRecoilValue(hasErrorSelector);

	return {
		userData,
		setUserData,
		pressSaved,
		hasError,
		setSaveData,
		errors,
		serverData,
		setServerData,
		setErrors,
	};
};
