import {  styled } from '@mui/material/styles';
import {Typography} from "@mui/material"
export const Title=styled(Typography)(()=>({

        fontFamily: "Poppins,  sans-serif",
        fontWeight: 600,
        color:"#283238",
        fontStyle: "normal",
    
      "&.MuiTypography-h1": {
        fontSize: "20px",
        lineHeight: "150%" 
      }})
)