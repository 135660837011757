import { FormControlLabel, Radio, Stack } from '@mui/material';
import React from 'react';

export const CustomRadioButton = ({ label, opt,value, id,onChange }) => {

	return (
		<Stack spacing={2}>
			<label
				htmlFor={id}
				style={{ display: 'block', marginBottom: '-12px' }}
			>
				{label}
			</label>
			{opt.map(op => (
				<FormControlLabel
					key={op.id}
					sx={{
						justifyContent: 'space-between',
						padding: '8px 10px',
						borderRadius: ' 10px',
						border: '1.5px solid',
						borderColor: '#515A5D',
						background: ' #FFF',
					}}
					onChange={() => onChange('gender', op.label)}
					label={op.label}
					labelPlacement="start"
					value={op.id}
					control={
						<Radio checked={value === op.label}></Radio>
					}
				></FormControlLabel>
			))}
		</Stack>
	);
};
