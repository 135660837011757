const REQUIRED = 'Campo obligatorio.';
const MIN_LENGTH = 'Debe contener 13 caracteres.';
const JUST_LETTERS = 'El campo deben ser solo letras.';
const EMAIL = 'Correo electrónico invalido.';
const JUST_NUMBERS = 'Solo se aceptan numeros en este campo';
const REG_LETTERS_ONLY = new RegExp('^[a-zA-Z ]+$');
const VALID_DATE = 'Debe ingresar una fecha valida';
const MIN_LENGTH_16 = 'Debe contener 18 caracteres.';
const REG_JUST_NUMBERS = /^[0-9]+$/;
const MIN_LENGTH_10 = 'Debe contener 10 caracteres';
const ALPHANUMERIC = 'El contenido del campo debe ser alfanumerico.';
const EMAIL_REGEX = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
export const validateChange = (key, value) => {
	switch (key) {
		case 'rfc':
			if (value === '') return REQUIRED;
			if (/[^\w\d &\-().]/g.test(value)) return ALPHANUMERIC;
			if (value.includes('.') || value.includes('-')) return ALPHANUMERIC;
			else if (value.length !== 10) return MIN_LENGTH;
			return '';
		case 'curp':
			if (value === '') return REQUIRED;
			if (/[^\w\d &\-().]/g.test(value)) return ALPHANUMERIC;
			if (value.includes('.') || value.includes('-')) return ALPHANUMERIC;
			else if (value.length !== 18) return MIN_LENGTH_16;
			return '';
		case 'email':
			if (value === '') return REQUIRED;
			else if (!EMAIL_REGEX.test(value)) return EMAIL;
			return '';
		case 'father_surname':
			if (value === '') return REQUIRED;
			else if (!REG_LETTERS_ONLY.test(value)) return JUST_LETTERS;
			return '';
		case 'status':
			if (value === '') return REQUIRED;
			else if (!REG_LETTERS_ONLY.test(value.replace('/', '')))
				return JUST_LETTERS;
			return '';
		case 'name':
			if (value === '') return REQUIRED;
			else if (!REG_LETTERS_ONLY.test(value)) return JUST_LETTERS;
			return '';
		case 'mother_surname':
			if (value === '') return REQUIRED;
			if (!REG_LETTERS_ONLY.test(value) && value !== '')
				return JUST_LETTERS;
			return '';
		case 'celphone':
			if (value === '') return REQUIRED;
			if (!REG_JUST_NUMBERS.test(value.replace('+', '')) && value !== '')
				return JUST_NUMBERS;
			if (value.length !== 10) {
				return MIN_LENGTH_10;
			}
			return '';
		case 'gender':
			if (value === '') return REQUIRED;
			if (!REG_LETTERS_ONLY.test(value) && value !== '')
				return JUST_LETTERS;
			return '';
		case 'born':
			if (value === '') return REQUIRED;
			else {
				const [d, m, y] = value.split('/');
				if (!d || !REG_JUST_NUMBERS.test(d)) {
					return VALID_DATE;
				}
				if (!m || !REG_JUST_NUMBERS.test(m)) {
					return VALID_DATE;
				}
				if (!y || !REG_JUST_NUMBERS.test(y)) {
					return VALID_DATE;
				}
				if (d < 1 || d > 31) return VALID_DATE;
				if (m < 1 || m > 12) return VALID_DATE;
				if (y < 1900 || y > new Date().getFullYear()) return VALID_DATE;
			}

			return '';
		default:
			return '';
	}
};
