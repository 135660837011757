import { Box, TextField } from "@mui/material";
import React from "react";
import { useUserData } from "../../state/atoms";
export const CustomTextField = ({
  label,
  onChange,
  value,
  disabled=false,
  id,
  handleBlur,
  type,
  sx,
}) => {
  const { errors } = useUserData();
  return (
    <Box sx={sx}>
      <label htmlFor={id} style={{ display: "block", marginBottom: "4px" }}>
        {label}
      </label>
      <TextField
        {...{ id, onChange, type }}
        onChange={(e) => {
          onChange(id, e.target.value);
        }}
        onBlur={(e) => {
          handleBlur(id, e.target.value);
        }}
        disabled={disabled}
        value={value}
        error={Boolean(errors[id])}
        helperText={errors[id]}
        sx={{
          ".MuiOutlinedInput-root": {
            background: "#FFF",
            borderColor: "#515A5D",
            borderRadius: " 10px",
            border: "solid 0.5px",
          },
          ".Mui-focused ": {
            border: "none",
          },
          ".Mui-error ": {
            border: "none",
          },
        }}
        fullWidth
      ></TextField>
    </Box>
  );
};
