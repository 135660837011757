import { Box, Button, Link } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useProgress, useTerms, useUserData } from '../state/atoms';
import { boxStyl, btnStyle, linkStyle } from './commons/styles';

const Footer = ({ type }) => {
	const { progress } = useProgress();
	const { terms, verfyFull } = useTerms();
	const navigate = useNavigate();
	const { setSaveData, hasError } = useUserData();

	switch (type) {
		case 'home':
			return (
				<Box sx={boxStyl}>
					<Button
						variant="contained"
						sx={btnStyle}
						onClick={() => navigate('/plan')}
					>
						Comenzar <ArrowForwardIcon sx={{ marginLeft: '2px' }} />{' '}
					</Button>
				</Box>
			);
		case 'thanks':
			return (
				<Box sx={boxStyl}>
					<Button
						variant="contained"
						sx={btnStyle}
						onClick={() => {
							navigate('/plan');
						}}
					>
						Ir a pagar{' '}
						<ArrowForwardIcon sx={{ marginLeft: '2px' }} />{' '}
					</Button>
				</Box>
			);
		case 'verify':
			return (
				<Box sx={boxStyl}>
					<Button
						variant="contained"
						sx={btnStyle}
						disabled={!verfyFull}
						onClick={() => navigate('/save-data')}
					>
						Siguiente
					</Button>
				</Box>
			);
		case 'plan':
			return (
				<Box sx={boxStyl}>
					<Button
						disabled={progress === 0}
						variant="contained"
						sx={btnStyle}
						onClick={() => navigate('/personal-data')}
					>
						¡Quiero este plan!
					</Button>
					<Button
						variant="outlined"
						sx={{
							...btnStyle,
							background: '#FFF',
							margin: '12px 0',
						}}
						onClick={() => navigate('/')}
					>
						<ArrowBackIcon sx={{ marginRight: '10px' }} /> Regresar
					</Button>
					<Link
						target={'_blank'}
						download
						href="/Beneficio Dental.pdf"
						sx={linkStyle}
					>
						<SaveAltIcon sx={{ marginRight: '10px' }} /> Descargar
						detalles de beneficios{' '}
					</Link>
				</Box>
			);
		case 'edit-plan':
			return (
				<Box sx={boxStyl}>
					<Button
						disabled={progress === 0}
						variant="contained"
						sx={btnStyle}
						onClick={() => navigate('/verify')}
					>
						¡Quiero este plan!
					</Button>
					<Button
						variant="outlined"
						sx={{
							...btnStyle,
							background: '#FFF',
							margin: '12px 0',
						}}
						onClick={() => navigate('/verify')}
					>
						<ArrowBackIcon sx={{ marginRight: '10px' }} /> Regresar
					</Button>
					<Link
						download
						target={'_blank'}
						href="/Beneficio Dental.pdf"
						sx={linkStyle}
					>
						<SaveAltIcon sx={{ marginRight: '10px' }} /> Descargar
						detalles de beneficios{' '}
					</Link>
				</Box>
			);
		case 'edit-userData':
			return (
				<Box sx={boxStyl}>
					<Button
						disabled={!terms}
						variant="contained"
						sx={btnStyle}
						onClick={() => {
							setSaveData(true);
						}}
					>
						Guardar cambios
					</Button>
					<Button
						variant="outlined"
						sx={{
							...btnStyle,
							background: '#FFF',
							margin: '12px 0',
						}}
						onClick={() => navigate('/verify')}
					>
						<ArrowBackIcon sx={{ marginRight: '10px' }} /> Regresar
					</Button>
				</Box>
			);
		case 'userData':
			return (
				<Box sx={boxStyl}>
					<Button
						disabled={!terms || hasError}
						variant="contained"
						sx={btnStyle}
						onClick={() => {
							setSaveData(true);
						}}
					>
						Siguiente
					</Button>
					<Button
						variant="outlined"
						sx={{
							...btnStyle,
							background: '#FFF',
							margin: '12px 0',
						}}
						onClick={() => navigate('/plan')}
					>
						<ArrowBackIcon sx={{ marginRight: '10px' }} /> Regresar
					</Button>
					<Link
						download
						target={'_blank'}
						href="/CGEN  Seguro Colectivo Accidentes Personales.pdf"
						sx={linkStyle}
					>
						<SaveAltIcon sx={{ marginRight: '10px' }} /> Descargar
						Condiciones generales{' '}
					</Link>
				</Box>
			);
		default:
			return <></>;
	}
};

export { Footer };
