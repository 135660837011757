import React from 'react';

import { Box } from '@mui/material';
import { PlanCard } from './PlanCard';
import { getItems } from './utils';
import { usePlan, useProgress } from '../state/atoms';

export const PlanOptions = () => {
	const { setProgress } = useProgress();
	const { selectedPlan, setSelectedPlan } = usePlan();
	const handleSelection = id => {
		setProgress(22);
		setSelectedPlan({ plan: id, cost: id === 'base' ? 250 : 840 });
	};
	return (
		<Box>
			<PlanCard
				plan="Plan Base"
				id="base"
				selected={selectedPlan.plan === 'base'}
				items={getItems(false)}
				cost={250}
				handleSelection={handleSelection}
			/>

			<PlanCard
				id="security"
				plan="Plan Seguridad"
				items={getItems(true)}
				selected={selectedPlan.plan === 'security'}
				handleSelection={handleSelection}
				cost={840}
			/>
		</Box>
	);
};
