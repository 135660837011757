import { Box, Link } from '@mui/material';
import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const InfoBox = () => {
	return (
		<Box
			sx={{
				display: 'flex',
				padding: '13px 8px',

				alignItems: 'flex-start',

				gap: '10px',
				alignSelf: 'stretch',
				background: '#E6F1FE',
				borderRadius: '8px',
				boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.10)',
				marginBottom: '25px',
			}}
		>
			<InfoOutlinedIcon sx={{ color: '#0050AC' }} />{' '}
			<p
				style={{
					marginTop: '3px',
					color: '#262626',
					fontFamily: 'Poppins',
					fontSize: '12px',
					fontStyle: 'normal',
					fontWeight: 400,
					lineHeight: '160%',
				}}
			>
				Consulta información y directorio de{' '}
				<Link
					target="_blank"
					style={{ color: '#0050AC', fontWeight: 600 }}
					href="https://dentalia.com/clinica/"
				>
					Dentalia
				</Link>
			</p>
		</Box>
	);
};
