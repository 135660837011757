import React from 'react';
export const CustomP = ({ userKey, value }) => {
	return (
		<p
			style={{
				fontSize: '14px',
				lineHeight: '24px',
			}}
		>
			{userKey}: <span style={{ fontWeight: 500 }}>{value}</span>
		</p>
	);
};
