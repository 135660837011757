export const linkStyle = {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    padding: '10px',
    fontSize: '14px',
    fontFamily: 'Poppins',
};
export const boxStyl = {
    position: 'sticky',
    bottom: 0,
    borderWidth: '6px 0',
    minHeight: '108px',
    boxShadow:
        'inset 0px 11px 8px -10px #CCC, inset 0px -11px 8px -10px #CCC',
    background: '#F8F9F9',

    flex: '1',
    height: '100%',
    justifyContent: 'center',

    alignItems: 'flex-start',
    padding: '20px',
};
export const btnStyle = {
    width: '100%',
    padding: '12px 24px',
    textTransform: 'none',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    borderRadius: '8px',
    background: '#045B9F',
};