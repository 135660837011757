import React from 'react';
import { CustomCard } from './CustomCard';
import { Box, Divider } from '@mui/material';
import { usePlan, useUserData } from '../state/atoms';
import { getItems } from './utils';
import { TitleWithEdit } from './commons/TitleWithEdit';
import { CustomP } from './commons/CustomP';
import { Terms } from './Terms';

export const VerifyPlan = () => {
	const { userData } = useUserData();
	const { selectedPlan } = usePlan();
	return (
		<Box>
			<CustomCard sx={{ paddingTop: '22px' }}>
				<TitleWithEdit to="/edit-plan" title={'Plan dental'} />
				<Box
					sx={{
						p: {
							fontSize: '14px',
							lineHeight: '24px',
						},
					}}
				>
					<p>
						{selectedPlan.plan === 'base'
							? 'Plan Base'
							: 'Plan Seguridad'}
					</p>
					{getItems(selectedPlan.plan === 'security').map(item => (
						<p key={item.text}>{item.text}</p>
					))}
					<Divider
						sx={{
							borderRadius: '8px',
							opacity: '0.6',
							background: '#1973B8',
							height: '2px',
						}}
					/>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							paddingRight: '40px',
						}}
					>
						<p>Costo anual</p>
						<p style={{ fontWeight: 600 }}>${selectedPlan.cost}</p>
					</Box>
				</Box>
			</CustomCard>
			<CustomCard sx={{ paddingTop: '22px' }}>
				<TitleWithEdit
					title={'Datos personales'}
					to="/edit-personal-data"
				/>
				<Box>
					<CustomP userKey="Nombres" value={userData.name} />
					<CustomP
						userKey="Apellidos"
						value={
							userData.father_surname +
							' ' +
							userData.mother_surname
						}
					/>
					<CustomP
						userKey="Fecha de nacimiento"
						value={userData.born}
					/>
					<CustomP userKey="Sexo" value={userData.gender} />
					<CustomP userKey="RFC" value={userData.rfc} />
					<CustomP
						userKey="Correo electrónico"
						value={userData.email}
					/>
					<CustomP
						userKey="Teléfono celular"
						value={userData.celphone}
					/>
				</Box>
			</CustomCard>
			<Terms text="Confirmo que mis datos son correctos" />
		</Box>
	);
};
