import { createBrowserRouter } from 'react-router-dom';
import { CommonLayout } from '../layouts/CommonLayout';
import { Home } from '../pages/Home';
import { Footer } from '../components/Footer';
import { Plan } from '../pages/Plan';
import { PersonalData } from '../pages/PersonalData';
import { Verify } from '../pages/Verify';
import { SaveData } from '../pages/SaveData';
import { End } from '../pages/End';
import {  AdminPortalLogin } from '../pages/AdminPortalLogin';
import {  AdminPortal } from '../pages/AdminPortal';

export const router = createBrowserRouter([
	{
		path: '/',
		element: (
			<CommonLayout bg footer={<Footer type={'home'} />}>
				<Home />
			</CommonLayout>
		),
	},
	{
		path: '/plan',
		element: (
			<CommonLayout footer={<Footer type={'plan'} />}>
				<Plan />
			</CommonLayout>
		),
	},
	{
		path: '/edit-plan',
		element: (
			<CommonLayout footer={<Footer type={'edit-plan'} />}>
				<Plan edit />
			</CommonLayout>
		),
	},
	{
		path: '/personal-data',
		element: (
			<CommonLayout footer={<Footer type={'userData'} />}>
				<PersonalData />
			</CommonLayout>
		),
	},
	{
		path: '/edit-personal-data',
		element: (
			<CommonLayout footer={<Footer type={'edit-userData'} />}>
				<PersonalData edit />
			</CommonLayout>
		),
	},
	{
		path: '/thanks',
		element: (
			<CommonLayout  bg footer={<></>}>
				<Home done />
			</CommonLayout>
		),
	},
	{
		path: '/admin-portal/bbva/login',
		element: (
			<AdminPortalLogin />
		),
	},
	{
		path: '/admin-portal/bbva/dashboard',
		element: (
			<AdminPortal />
		),
	},
	{
		path: '/end-flow/:id',
		element: (
			<CommonLayout  bg footer={<></>}>
				<End done />
			</CommonLayout>
		),
	},
	{
		path: '/save-data',
		element: <SaveData />,
	},
	{
		path: '/verify',
		element: (
			<CommonLayout footer={<Footer type={'verify'} />}>
				<Verify />
			</CommonLayout>
		),
	},
]);
