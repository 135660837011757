import React from 'react';
import { Stack, Box } from '@mui/material';
import { Header } from './../components/Header.jsx';
export const CommonLayout = ({ children, footer,bg,help }) => {
	return (
		<Stack
			sx={{
				margin: 'auto',
				maxWidth: '600px',
				padding: '0 !important',
				overflow: 'hidden',
			}}
		>
			<Header  help={help}/>
			<Box
				sx={{
					borderWidth: '6px 0',
					marginTop: '16px',
					boxShadow:
						'inset 0px 11px 8px -10px #CCC, inset 0px -11px 8px -10px #CCC',
					backgroundColor: bg?"#FFF":'#F8F9F9',
					padding: '0 22px',
				}}
			>
				{children}
			</Box>
			{footer}
		</Stack>
	);
};
