import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { esES } from '@mui/x-date-pickers/locales';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/es';

export default function BasicDatePicker({ label, handleChange, id }) {
	return (
		<LocalizationProvider
			dateAdapter={AdapterDayjs}
			adapterLocale="es"
			localeText={
				esES.components.MuiLocalizationProvider.defaultProps.localeText
			}
		>
			<DatePicker
				onChange={date => handleChange(date, id)}
				label={label}
			/>
		</LocalizationProvider>
	);
}
