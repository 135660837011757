import React from 'react';
import { Box, Button } from '@mui/material';
import logoImage from "./../img/bbva-header.png"

export const Header = ({ help }) => {
	return (
		<Box
			sx={{
				padding: '20px',
				paddingBottom: '0',
				display: 'flex',
				justifyContent: 'space-between',
			}}
		>
			<img src={logoImage} alt="logo de bbva"></img>
			{help && <Button variant='outlined' sx={{fontSize:"16px",textTransform:"none",svg:{marginRight:"8px"}}}>
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<rect width="24" height="24" fill="white"/>
<path d="M22 13.75C21.9855 12.9197 21.713 12.1146 21.2202 11.4462C20.7274 10.7778 20.0388 10.2794 19.25 10.02V9.75C19.25 5.33 16.13 2 12 2C7.87 2 4.75 5.33 4.75 9.75V10.02C3.96116 10.2794 3.27259 10.7778 2.77982 11.4462C2.28705 12.1146 2.01451 12.9197 2 13.75C2.03397 14.8437 2.50061 15.8793 3.29745 16.6293C4.0943 17.3792 5.15621 17.7823 6.25 17.75V9.75C6.25 6.19 8.72 3.5 12 3.5C15.28 3.5 17.75 6.19 17.75 9.75V18.25C17.7474 18.7133 17.5622 19.1569 17.2345 19.4845C16.9069 19.8122 16.4633 19.9974 16 20H13.29C13.1249 19.714 12.8701 19.4905 12.565 19.3642C12.2599 19.2378 11.9217 19.2156 11.6027 19.3011C11.2838 19.3866 11.0019 19.5749 10.8009 19.8369C10.5999 20.0988 10.491 20.4198 10.491 20.75C10.491 21.0802 10.5999 21.4012 10.8009 21.6631C11.0019 21.9251 11.2838 22.1134 11.6027 22.1989C11.9217 22.2844 12.2599 22.2622 12.565 22.1358C12.8701 22.0095 13.1249 21.786 13.29 21.5H16C16.8611 21.4974 17.6863 21.1541 18.2952 20.5452C18.9041 19.9363 19.2474 19.1111 19.25 18.25V17.48C20.0388 17.2206 20.7274 16.7222 21.2202 16.0538C21.713 15.3854 21.9855 14.5803 22 13.75Z" fill="#1973B8"/>
</svg> Ayuda
				</Button>}
		</Box>
	);
};
