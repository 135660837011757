import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { StepConnector, styled } from '@mui/material';

const getIcon = (index, done) => {
	if (index === 0) {
		return done ? (
			<span
				style={{
					backgroundColor: '#045B9F',
					padding: '10px',
					height: '24px',
					width: '24px',
					borderRadius: 60,
					marginLeft: '-10px',
				}}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
				>
					<path
						d="M9.5501 18L3.8501 12.3L5.2751 10.875L9.5501 15.15L18.7251 5.97498L20.1501 7.39998L9.5501 18Z"
						fill="white"
					/>
				</svg>
			</span>
		) : (
			<span
				style={{
					backgroundColor: '#DBFFFF',
					padding: '10px',
					borderRadius: 60,
					marginLeft: '-10px',
				}}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="28"
					height="28"
					viewBox="0 0 24 24"
					fill="none"
				>
					<path
						d="M10.5 15.5H13.5V13H16V10H13.5V7.5H10.5V10H8V13H10.5V15.5ZM12 22C9.68333 21.4167 7.77083 20.0875 6.2625 18.0125C4.75417 15.9375 4 13.6333 4 11.1V5L12 2L20 5V11.1C20 13.6333 19.2458 15.9375 17.7375 18.0125C16.2292 20.0875 14.3167 21.4167 12 22ZM12 19.9C13.7333 19.35 15.1667 18.25 16.3 16.6C17.4333 14.95 18 13.1167 18 11.1V6.375L12 4.125L6 6.375V11.1C6 13.1167 6.56667 14.95 7.7 16.6C8.83333 18.25 10.2667 19.35 12 19.9Z"
						fill="#045B9F"
					/>
				</svg>
			</span>
		);
	} else if (index === 1) {
		return done ? (
			<span
				style={{
					backgroundColor: '#045B9F',
					padding: '10px',
					height: '24px',
					width: '24px',
					borderRadius: 60,
					marginLeft: '-10px',
				}}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
				>
					<path
						d="M9.5501 18L3.8501 12.3L5.2751 10.875L9.5501 15.15L18.7251 5.97498L20.1501 7.39998L9.5501 18Z"
						fill="white"
					/>
				</svg>
			</span>
		) : (
			<span
				style={{
					backgroundColor: '#DBFFFF',
					padding: '10px',
					borderRadius: 60,
					marginLeft: '-10px',
				}}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
				>
					<path
						d="M18 18C18.4167 18 18.7708 17.8542 19.0625 17.5625C19.3542 17.2708 19.5 16.9167 19.5 16.5C19.5 16.0833 19.3542 15.7292 19.0625 15.4375C18.7708 15.1458 18.4167 15 18 15C17.5833 15 17.2292 15.1458 16.9375 15.4375C16.6458 15.7292 16.5 16.0833 16.5 16.5C16.5 16.9167 16.6458 17.2708 16.9375 17.5625C17.2292 17.8542 17.5833 18 18 18ZM18 21C18.5 21 18.9667 20.8833 19.4 20.65C19.8333 20.4167 20.1917 20.0917 20.475 19.675C20.0917 19.4417 19.6917 19.2708 19.275 19.1625C18.8583 19.0542 18.4333 19 18 19C17.5667 19 17.1417 19.0542 16.725 19.1625C16.3083 19.2708 15.9083 19.4417 15.525 19.675C15.8083 20.0917 16.1667 20.4167 16.6 20.65C17.0333 20.8833 17.5 21 18 21ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V11.7C20.6833 11.55 20.3583 11.4208 20.025 11.3125C19.6917 11.2042 19.35 11.125 19 11.075V5H5V19H11.05C11.1 19.3667 11.1792 19.7167 11.2875 20.05C11.3958 20.3833 11.525 20.7 11.675 21H5ZM5 18V19V5V11.075V11V18ZM7 17H11.075C11.125 16.65 11.2042 16.3083 11.3125 15.975C11.4208 15.6417 11.5417 15.3167 11.675 15H7V17ZM7 13H13.1C13.6333 12.5 14.2292 12.0833 14.8875 11.75C15.5458 11.4167 16.25 11.1917 17 11.075V11H7V13ZM7 9H17V7H7V9ZM18 23C16.6167 23 15.4375 22.5125 14.4625 21.5375C13.4875 20.5625 13 19.3833 13 18C13 16.6167 13.4875 15.4375 14.4625 14.4625C15.4375 13.4875 16.6167 13 18 13C19.3833 13 20.5625 13.4875 21.5375 14.4625C22.5125 15.4375 23 16.6167 23 18C23 19.3833 22.5125 20.5625 21.5375 21.5375C20.5625 22.5125 19.3833 23 18 23Z"
						fill="#045B9F"
					/>
				</svg>
			</span>
		);
	} else {
		return (
			<span
				style={{
					backgroundColor: '#DBFFFF',
					padding: '10px',
					borderRadius: 60,
					marginLeft: '-10px',
				}}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
				>
					<path
						d="M22 6V18C22 18.55 21.8042 19.0208 21.4125 19.4125C21.0208 19.8042 20.55 20 20 20H4C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H20C20.55 4 21.0208 4.19583 21.4125 4.5875C21.8042 4.97917 22 5.45 22 6ZM4 8H20V6H4V8ZM4 12V18H20V12H4Z"
						fill="#045B9F"
					/>
				</svg>
			</span>
		);
	}
};
const VerticalLine = styled(StepConnector)(({ index }) => ({
	span: {
		height: '41px',
	},
}));
const steps = [
	{
		label: '1. Planes dentales',
		description: `Elige el plan que mejor te funcione.`,
	},
	{
		label: '2. Datos personales',
		description: 'Algunos datos sobre ti.',
	},
	{
		label: '3. Pago',
		description: `Selecciona el método de pago que prefieras.`,
	},
];

export default function VerticalLinearStepper({ done }) {
	return (
		<Box sx={{ maxWidth: 400 }}>
			<Stepper
				orientation="vertical"
				connector={<VerticalLine />}
				sx={{
					'.Mui-disabled, .Mui-active': { color: '#283238' },
					'.MuiStepLabel-label,.MuiStepLabel-label.Mui-active': {
						color: '#283238',
						/* UI-Label/Multiple Line/Large/Strong */
						fontFamily: 'Poppins',
						fontSize: '16px',
						fontStyle: 'normal',
						fontWeight: 500,
					},
				}}
			>
				{steps.map((step, index) => (
					<Step
						key={step.label}
						expanded={true}
						sx={{ position: 'relative', fontFamily: 'Poppins' }}
					>
						<StepLabel
							StepIconComponent={() => getIcon(index, done)}
							sx={{
								color: '#283238',
								fontSize: '16px',
								fontStyle: 'normal',
								fontFamily: 'Poppins',
								paddingLeft: '12px',
								fontWeight: 700,
								alignItems: 'flex-start',
								padding: 0,
								span: {},
							}}
							optional={
								<Typography
									variant="caption"
									sx={{
										fontFamily: 'Poppins',
										marginLeft: '12px',
										fontSize: '14px',
										position: 'absolute',
									}}
								>
									{step.description}
								</Typography>
							}
						>
							<span
								style={{
									marginLeft: '12px',
									marginTop: '-6px',
								}}
							>
								{step.label}
							</span>
						</StepLabel>
					</Step>
				))}
			</Stepper>
		</Box>
	);
}
