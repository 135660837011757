import React, { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Stack } from '@mui/material';
import BasicDateRangePicker from '../components/commons/DateRange';
import { btnStyle } from '../components/commons/styles';
import { axiosInstance } from '../utils';

export const AdminPortal = () => {
	const [dates, setDates] = useState({});
	const navigate = useNavigate();
	const handleClick = () => {
		axiosInstance
			.post('/api/admin/users', { ...dates }, { responseType: 'blob' })
			.then(data => {
				const exel = new Blob([data.data], {
					type: 'application/xlsx',
				});
				const url = window.URL.createObjectURL(exel);

				// Create a temporary <a> element to trigger the download
				const tempLink = document.createElement('a');
				tempLink.href = url;
				tempLink.setAttribute(
					'download',
					`users_${dates.startDate}_${dates.endDate}.xlsx`
				); // Set the desired filename for the downloaded file

				// Append the <a> element to the body and click it to trigger the download
				document.body.appendChild(tempLink);
				tempLink.click();

				// Clean up the temporary elements and URL
				document.body.removeChild(tempLink);
				window.URL.revokeObjectURL(url);
				// console.log(typeof data.data)
				// var url  = window.URL.createObjectURL(data.data);
				// window.location.assign(url);
				// downloadFile(data.data);
			})
			.catch(err => console.log(err));
	};
	useEffect(() => {
		const token = window.sessionStorage.getItem('token');
		if (!token) navigate('/admin-portal/bbva/login');
		return () => {
			clearInterval();
		};
	}, []);
	setInterval(() => {
		const token = window.sessionStorage.getItem('token');
		if (!token) navigate('/admin-portal/bbva/login');
		else {
			const userData = jwtDecode(token);
			const actualDate = new Date().getTime();
			if (!token || userData.exp * 1000 - actualDate < 0) {
				window.sessionStorage.removeItem('token');
				navigate('/admin-portal/bbva/login');
			}
		}
		// console.log(new Date().getTime() + ''.split('').slice(5, 0).join(''));
	}, 5000);
	const handleRangeSelect = (range, key) => {
		if (key === 'start') {
			setDates({ ...dates, startDate: range.format('DD/MM/YYYY') });
		} else {
			setDates({ ...dates, endDate: range.format('DD/MM/YYYY') });
		}
	};
	return (
		<Card
			sx={{
				width: '400px',
				margin: '40px auto',
				padding: '20px 30px',
				borderTop: 'solid 1px grey',
			}}
		>
			<Stack spacing={4} display={'flex'}>
				<BasicDateRangePicker
					handleChange={handleRangeSelect}
					label="Desde"
					id="start"
				/>
				<BasicDateRangePicker
					handleChange={handleRangeSelect}
					label="Hasta"
					id="end"
				/>
			</Stack>
			<Button
				sx={{ ...btnStyle, marginTop: '20px' }}
				onClick={handleClick}
				variant="contained"
			>
				Importar cvs
			</Button>
		</Card>
	);
};
