import React, { useState } from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import { btnStyle } from '../components/commons/styles';
import { useParams } from 'react-router-dom';
import { axiosInstance } from '../utils';
import thanksImage from './../img/thanks.png';

export const End = () => {
	const { id } = useParams();
	const [loading, setLoading] = useState(false);
	console.log(id);
	const handleClick = () => {
		setLoading(true);
		axiosInstance
			.get(`/api/users/certificate/${id}`, { responseType: 'blob' })
			.then(data => {
				console.log(data.data);
				const pdf = new Blob([data.data], {
					type: 'application/pdf',
				});
				const url = window.URL.createObjectURL(pdf);

				// Create a temporary <a> element to trigger the download
				const tempLink = document.createElement('a');
				tempLink.href = url;
				tempLink.setAttribute(
					'download',
					`Plan Dental - Certificado cobrandeado.pdf`
				); // Set the desired filename for the downloaded file

				// Append the <a> element to the body and click it to trigger the download
				document.body.appendChild(tempLink);
				tempLink.click();

				// Clean up the temporary elements and URL
				document.body.removeChild(tempLink);
				window.URL.revokeObjectURL(url);
				setLoading(false);
			})
			.catch(err => {
				console.log(err);
				setLoading(false);
			});
	};
	return (
		<Box
			sx={{
				fontFamily: 'Poppins',
				h1: {
					fontSize: '22px',
					fontStyle: 'normal',
					fontWeight: 600,
					lineHeight: '133%',
				},
				p: {
					fontSize: '14px',
					fontStyle: 'normal',
					fontWeight: 400,
					lineHeight: '133%',
				},
			}}
		>
			<img src={thanksImage} alt="thanks"></img>
			<h1>¡Muchas gracias!</h1>
			<p>Contrataste exitosamente nuestro plan dental.</p>

			<Box
				sx={{
					marginTop: '57px',
					svg: {
						marginLeft: '8px',
					},
				}}
			>
				<Button
					variant="contained"
					sx={btnStyle}
					loading={loading || undefined}
					onClick={() => {
						!loading && handleClick();
					}}
				>
					{loading ? (
						<Box
							sx={{
								height: '26px',
								'.MuiCircularProgress-root': {
									position: 'relative',

									rotate: 'none',
									color: 'white',
								},
								svg: {
									marginLeft: 0,
								},
							}}
						>
							<CircularProgress size={30} />
						</Box>
					) : (
						<>
							Descargar certificado
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="21"
								height="20"
								viewBox="0 0 21 20"
								fill="none"
							>
								<path
									d="M10.4999 13.3333L6.33325 9.16665L7.49992 7.95831L9.66658 10.125V3.33331H11.3333V10.125L13.4999 7.95831L14.6666 9.16665L10.4999 13.3333ZM5.49992 16.6666C5.04159 16.6666 4.64922 16.5035 4.32284 16.1771C3.99645 15.8507 3.83325 15.4583 3.83325 15V12.5H5.49992V15H15.4999V12.5H17.1666V15C17.1666 15.4583 17.0034 15.8507 16.677 16.1771C16.3506 16.5035 15.9583 16.6666 15.4999 16.6666H5.49992Z"
									fill="white"
								/>
							</svg>
						</>
					)}
				</Button>
			</Box>
		</Box>
	);
};
