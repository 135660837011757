import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/Error';
import { useNavigate } from 'react-router-dom';
import {axiosInstance}from "./../utils"
import { useCountUp } from 'use-count-up';

import { usePlan, useUserData } from '../state/atoms';
export const Progress = () => {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState(false);

	const [error, setError] = useState('');
	const { userData, setServerData } = useUserData();
	const { selectedPlan } = usePlan();

	const { value } = useCountUp({
		isCounting: isLoading,
		start: 0,
		end: 100,
	});

	useEffect(() => {
		axiosInstance
			.post(
				'/api/users',
				{ ...userData, ...selectedPlan },
				{
					withCredentials: false,
					headers: {
						'Content-Type': 'application/json',
						'Access-Control-Allow-Origin': '*',
					},
				}
			)
			.then(data => {
				setData(data);
				setServerData(data.data);
				setIsLoading(false);
			})
			.catch(err => {
				setError(err.message);
				setIsLoading(false);
			});
	}, []);
	useEffect(() => {
		if (error)
			setTimeout(() => {
				navigate('/verify');
			}, 3000);

		if (data)
			setTimeout(() => {
				navigate('/thanks');
			}, 3000);
		if (error) console.log('error ', error);
	}, [data, error]);
	return (
		<>
			<Box sx={{ height: '120px', position: 'relative' }}>
				{isLoading && (
					<CircularProgress
						value={value}
						size={110}
						thickness={6}
						disableShrink={isLoading}
						sx={{}}
					></CircularProgress>
				)}
				{!isLoading && error.length > 0 ? (
					<ErrorIcon
						color="error"
						sx={{ fontSize: '132px', marginTop: '-10px' }}
					/>
				) : (
					!isLoading && (
						<CheckCircleOutlineIcon
							color="primary"
							sx={{ fontSize: '132px', marginTop: '-10px' }}
						/>
					)
				)}
			</Box>
			<h3>
				{isLoading
					? 'Por favor espera un momento mientras guardamos tu información'
					: error.length > 0
						? '¡Ups! Algo salió mal.'
						: '¡Información guardada!'}
			</h3>
			<p style={{ marginTop: '0' }}>
				Nuestro plan dental te ofrece cobertura en Dentalia
			</p>
		</>
	);
};
