import { RouterProvider } from 'react-router-dom';
import { router } from './config/routes';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

import { RecoilRoot } from 'recoil';

function App() {
	const THEME = createTheme({
		palette: {
			primary: {
			  main: "#045B9F"
			},
			text:{
				
			},
			action:{
				disabled:"#586065",
				disabledBackground:"#E8EAEB"
			}
		},
		overrides:{
			MuiButton:{
				raisedPrimary:{
					color:"red"
				}
			}
		}
		
		
	});
	return (
		<Box sx={{ minHeight: '100vh' }}>
			<RecoilRoot>
				<ThemeProvider theme={THEME}>
					<RouterProvider router={router} />
				</ThemeProvider>
			</RecoilRoot>
		</Box>
	);
}

export default App;
