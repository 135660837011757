import { Card } from '@mui/material';
import React from 'react';
export const CustomCard = ({ children, sx }) => {
	return (
		<Card
			sx={{
				borderRadius: '10px',
				border: 'none',
				padding: '10px',
				fontFamily: 'Poppins',
				flex: 1,
				margin: '29px 0',
				background: 'var(--Neutral-white, #FFF)',
				boxShadow: '0px 4px 4px 0px rgba(40, 40, 40, 0.25)',
				...sx,
			}}
		>
			{children}
		</Card>
	);
};
