import { Box } from '@mui/material';
import React from 'react';
import { LinealProgess } from './LinealProgess';
import { useProgress } from '../state/atoms';

export const ProgressHeader = ({ title }) => {
	const {progress}=useProgress();
	return (
		<Box sx={{ padding: '12px 0 12px 0' }}>
			<p
				style={{
					margin: '0 0 8px 10px',
					color: '#4E575A',

					fontFamily: 'Poppins',
					fontSize: '12px',
					fontStyle: 'normal',
					fontWeight: 600,
				}}
			>
				{title}
			</p>
			<LinealProgess variant="determinate" value={progress} />
		</Box>
	);
};
