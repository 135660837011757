import { Button, Card } from '@mui/material';
import React, { useState } from 'react';
import { btnStyle } from '../components/commons/styles';
import { CustomTextField } from '../components/commons/CustomTextField';
import { useNavigate } from 'react-router-dom';
import { axiosInstance } from '../utils';
export const AdminPortalLogin = () => {
	const [user, setUser] = useState({ name: '', password: '' });
	const [error, setError] = useState('');
	const navigate = useNavigate();
	const handleUserLoged = token => {
		window.sessionStorage.setItem('token', token);
		navigate('/admin-portal/bbva/dashboard');
	};
	const handleLogin = () => {
		axiosInstance
			.post('/api/admin/login', { ...user })
			.then(data => {
				if (data.data.error) setError(data.data.error);
				else if (data.data.token) handleUserLoged(data.data.token);
			})
			.catch(err => console.log(err));
	};
	return (
		<Card sx={{ width: '400px', margin: 'auto', padding: '20px 30px' }}>
			<h1>Admin portal</h1>
			<CustomTextField
				label={'Usuario'}
				id={'user'}
				error={error}
				value={user.name}
				onChange={(key, value) => setUser({ ...user, name: value })}
				handleBlur={() => {}}
			/>
			<CustomTextField
				sx={{ marginTop: '20px' }}
				label={'Contraseña'}
				id={'password'}
				type="password"
				error={error}
				value={user.password}
				handleBlur={() => {}}
				onChange={(key, value) => setUser({ ...user, password: value })}
			/>
			<span style={{ color: 'red', display: 'block', marginTop: '20px' }}>
				{error}
			</span>
			<Button
				variant="contained"
				sx={{ ...btnStyle, marginTop: '20px' }}
				onClick={handleLogin}
			>
				Login
			</Button>
		</Card>
	);
};
