import axios from "axios";

export const axiosInstance = axios.create({
  baseURL:
    process.env.BASE_URL ||
    "https://api.plan-dental.koltin.mx/" ||
    "https://67jbw52x-3030.brs.devtunnels.ms/",
  timeout: 20000,
  headers: {
    withCredentials: false,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});
