import React from 'react';
import { Box } from '@mui/material';
import { ProgressHeader } from '../components/ProgressHeader';
import { Title } from '../components/commons/Title';
import { UserData } from '../components/UserData';
import { SubTitle } from '../components/commons/SubTitle';

export const PersonalData = ({ edit }) => {
	return (
		<Box sx={{ fontFamily: 'Poppins' }}>
			{!edit && (
				<ProgressHeader
					title="2. Datos personales"
					progress={66}
				></ProgressHeader>
			)}
			<Title
				variant="h1"
				sx={{
					color: '#283238',
					fontSize: '22px',
					marginTop: '10px',
				}}
			>
				{edit
					? 'Datos personales'
					: 'Ayúdanos completando la siguiente información'}
			</Title>
			<SubTitle
				sx={{
					color: '#283238',
					fontSize: '14px',
					marginTop: '16px',

					marginBottom: '38px',
				}}
			>
				{edit
					? 'Por favor, revisa la información a continuación'
					: 'Por favor, responde a continuación'}
			</SubTitle>
			<UserData edit />
		</Box>
	);
};
