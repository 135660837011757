import { Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import { CustomTextField } from "./commons/CustomTextField";
import { CustomRadioButton } from "./CustomRadioButton";
import { Terms } from "./Terms";
import { useProgress, useUserData } from "../state/atoms";
import { useNavigate } from "react-router-dom";
import { validateChange } from "./validator";
import { CustomPhoneField } from "./commons/CustomPhoneField";

export const UserData = ({ edit }) => {
  const { userData, pressSaved, setSaveData, setUserData, setErrors, errors } =
    useUserData();
  const { progress, setProgress } = useProgress();
  const navigate = useNavigate();
  const [data, setData] = useState(userData);
  const handleRFC = (key, value) => {
    let copyOfUserData = { ...data };
    let rfc = copyOfUserData.rfc.split("");
    if (key === "father_surname" && value.length > 0) {
      const vocales = value.match(/[aeiou]/gi);
      if (rfc) {
        rfc[0] = value[0];
        if (vocales && vocales[0]) rfc[1] = vocales[0];
      } else if (value.length > 0) rfc[0] = value[0];
    } else if (key === "mother_surname" && value.length > 0) {
      if (rfc) rfc[2] = value[0];
    } else if (key === "name") {
      if (rfc) rfc[3] = value[0];
    } else if (key === "born") {
      if (value.length > 9) {
        if (rfc) {
          const year = value.substring(value.length - 2);
          const month = value.split("/")[1];
          const day = value.split("/")[0];
          const aux = rfc.join("").substring(0, 4) + year + month + day;
          rfc = aux.split("");
          console.log(aux);
        }
      }
    }
    copyOfUserData.rfc = rfc.join("").toUpperCase();
    return copyOfUserData;
  };

  const handleChange = (key, value) => {
    let copyOfUserData = handleRFC(key, value);

    setErrors({ ...errors, [key]: validateChange(key, value) });

    if (data[key].length === 0) {
      setProgress(progress + 5);
    } else if (userData[key].length === 1 && !value) {
      setProgress(progress - 5);
    }
    copyOfUserData[key] = value;
    setData(copyOfUserData);
  };
  const handleBlur = (key, value) => {};
  const validateData = () => {
    console.log(data);
    var hasError = false;
    const localErrors = { ...errors };
    Object.keys(data).forEach((key) => {
      if (data[key] === "") {
        localErrors[key] = "Campo obligatorio";
        hasError = true;
      } else if (localErrors[key]) {
        localErrors[key] = "";
      }
    });
    setErrors(localErrors);
    console.log(localErrors);
    return !hasError;
  };
  useEffect(() => {
    if (pressSaved) {
      if (validateData()) {
        setUserData(data);
        setSaveData(false);
        navigate("/verify");
      } else {
        setSaveData(false);
      }
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressSaved]);
  return (
    <Stack spacing={2}>
      <CustomTextField
        label="Nombres"
        value={data.name}
        onChange={handleChange}
        handleBlur={handleBlur}
        id="name"
      />

      <CustomTextField
        label="Apellido paterno"
        value={data.father_surname}
        handleBlur={handleBlur}
        onChange={handleChange}
        id="father_surname"
      />
      <CustomTextField
        handleBlur={handleBlur}
        label="Apellido materno"
        value={data.mother_surname}
        onChange={handleChange}
        id="mother_surname"
      />
      <CustomTextField
        handleBlur={handleBlur}
        label="Fecha de nacimiento (DD/MM/AAAA)"
        value={data.born}
        onChange={handleChange}
        id="born"
      />
      <CustomRadioButton
        handleBlur={handleBlur}
        label="Sexo"
        opt={[
          { label: "Mujer", selected: false, id: "1" },
          { label: "Hombre", selected: true, id: "2" },
        ]}
        value={data.gender}
        onChange={handleChange}
      />
      <CustomTextField
        handleBlur={handleBlur}
        label="Estado civil"
        onChange={handleChange}
        value={data.status}
        id="status"
      />
      <CustomTextField
        handleBlur={handleBlur}
        label="CURP"
        value={data.curp}
        onChange={handleChange}
        id="curp"
      />
      <CustomTextField
        handleBlur={handleBlur}
        label="RFC"
        value={data.rfc}
        disabled
        id="rfc"
      />
      <CustomTextField
        handleBlur={handleBlur}
        label="Correo electrónico"
        value={data.email}
        onChange={handleChange}
        id="email"
      />
      <CustomPhoneField
        handleBlur={handleBlur}
        label="Teléfono celular"
        value={data.celphone}
        onChange={handleChange}
        id="celphone"
      />
      <Terms text={"Acepto y entiendo los términos y condiciones"} link />
    </Stack>
  );
};
