import { Box, Checkbox, FormControlLabel, Link } from '@mui/material';
import React from 'react';
import { useTerms } from '../state/atoms';

export const Terms = ({ text, link }) => {
	const { terms, setTerms, setVerifyFull, verfyFull } = useTerms();
	return (
		<Box
			sx={{
				padding: '20px 0',
				color: '#045B9F',
				display: 'flex',
				alignItems: 'center',
			}}
		>
			{link ? (
				<>
					<Checkbox
						onClick={() => setTerms(!terms)}
						checked={terms}
						sx={{
							width: '22px',
							color: '#0B7FA2',
							fill: '#0B7FA2',
						}}
					/>{' '}
					<Link
						href="https://koltin.mx/aviso-privacidad"
						target={"_blank"}
						sx={{
							paddingLeft: '20px',
							color: '#045B9F',
							textDecoration: link ? 'underline' : 'none',
							fontFamily: 'Poppins',
							fontSize: '14px',
							fontWeight: 500,
						}}
					>
						{text}
					</Link>
				</>
			) : (
				<FormControlLabel
					onChange={() => setVerifyFull(!verfyFull)}
					control={
						<Checkbox
							checked={verfyFull}
							sx={{
								color: '#0B7FA2',
								fill: '#0B7FA2',
								paddingRight: '12px',
							}}
						/>
					}
					sx={{
						
						textDecoration: link ? 'underline' : 'none',
						
						
						span:{
							color: '#045B9F',
							fontFamily: 'Poppins !important',
							fontSize: '14px',
						fontWeight: 500,
						}
					}}
					label={text}
				></FormControlLabel>
			)}
		</Box>
	);
};
