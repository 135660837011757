import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { ProgressHeader } from '../components/ProgressHeader';
import { Title } from '../components/commons/Title';
import { VerifyPlan } from '../components/VerifyPlan';
import { useProgress } from '../state/atoms';
export const Verify = () => {
	const { setProgress } = useProgress();
	useEffect(() => {
		setProgress(100);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<Box sx={{ fontFamily: 'Poppins' }}>
			<ProgressHeader title="2. Datos personales" progress={100} />
			<Title
				variant="h1"
				sx={{
					color: '#283238',
					fontSize: '22px',
					marginTop: '10px',
				}}
			>
				Verifica que tu información sea correcta
			</Title>
			<VerifyPlan />
		</Box>
	);
};
