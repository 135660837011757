import { Box, TextField } from '@mui/material';
import React from 'react';
import { useUserData } from '../../state/atoms';

export const CustomPhoneField = ({
	label,
	onChange,
	value,
	id,
	handleBlur,
	type,
	sx,
}) => {
	const formatValue = value => {
		console.log(typeof value);

		return ' ' + value
	};
	const { errors } = useUserData();
	return (
		<Box sx={sx}>
			<label
				htmlFor={id}
				style={{ display: 'block', marginBottom: '4px' }}
			>
				{label}
			</label>
			<TextField
				{...{ id, onChange, type }}
				onChange={e => {
					console.log(e.target.value);
					onChange(id, e.target.value.split(' ').join('').trim());
				}}
				InputProps={{
					startAdornment: <b style={{ marginLeft: '10px' }}>+52</b>,
				}}
				onBlur={e => {
					handleBlur(id, e.target.value);
				}}
				value={formatValue(value)}
				error={Boolean(errors[id])}
				helperText={errors[id]}
				sx={{
					'.MuiOutlinedInput-root': {
						background: '#FFF',
						borderColor: '#515A5D',
						borderRadius: ' 10px',
						border: 'solid 0.5px',
					},
					'.Mui-focused ': {
						border: 'none',
					},
					'.Mui-error ': {
						border: 'none',
					},
				}}
				fullWidth
			></TextField>
		</Box>
	);
};
