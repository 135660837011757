import { Box, Card, Divider, Grid, Link, Radio } from '@mui/material';
import React from 'react';
import { usePlan } from '../state/atoms';

export const PlanCard = ({
	plan,
	id,
	items,
	cost,
	selected,
	handleSelection,
}) => {
	const { selectedPlan } = usePlan();
	return (
		<Link
			onClick={e => {
				e.preventDefault();
				handleSelection(id);
			}}
			href="#"
			sx={{ textDecoration: 'none' }}
		>
			<Card
				sx={{
					borderRadius: '10px',
					border:
						selectedPlan.plan === id
							? '1.5px solid #0B7FA2'
							: '1px solid #A29F9F',
					padding: '10px',
					fontFamily: 'Poppins',
					flex: 1,
					margin: '29px 0',
					background: 'var(--Neutral-white, #FFF)',
					boxShadow: '0px 4px 4px 0px rgba(40, 40, 40, 0.25)',
				}}
			>
				<Box
					sx={{
						'.MuiButtonBase-root.MuiRadio-root': {
							width: '40px',
						},
						display: 'flex',
						justifyContent: 'space-between',
					}}
					justifyContent={'space-between'}
				>
					<p
						style={{
							color: '#283238',
							fontFamily: 'Poppins',
							fontSize: '14px',
							fontStyle: 'normal',
							fontWeight: 600,
							display: 'inline-block',
							width: '60%',
						}}
					>
						{plan}
					</p>
					<Radio width={22} checked={selected} />
				</Box>
				<Divider />
				<Grid container sx={{ padding: '10px' }} spacing={2}>
					{items.map((item, i) => (
						<Grid
							item
							xs={12}
							sx={{ alignContent: 'center' }}
							key={i}
						>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'flex-start',
									alignItems: 'center',
								}}
							>
								{item.img}
								<span
									style={{
										marginLeft: '12px',

										fontSize: '14px',
									}}
								>
									{item.text}
								</span>
							</Box>
						</Grid>
					))}
				</Grid>
				<Box
					sx={{
						marginTop: '20px',
						backgroundColor: '#DBFFFF',
						color: '#262626',
						textAlign: 'center',
						fontSize: '16px',
						padding: '5px',
						fontStyle: 'normal',
					}}
				>
					Costo anual por persona: <b>${cost}</b>
				</Box>
			</Card>
		</Link>
	);
};
