import React from 'react';
import { Progress } from '../components/Progress';
import { Box } from '@mui/material';
import { Header } from '../components/Header';

export const SaveData = () => {
	return (
		<Box>
			<Header help /> 
			<Box
				sx={{
					background: '#FFF',
					padding: '128px 20px',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',

					fontFamily: 'Poppins',
					textAlign: 'center',
					h3: {
						fontSize: '16px',
						fontStyle: 'normal',
						fontWeight: 600,
						lineHeight: '133%',
						marginTop: '40px',
						marginBottom: '70px',
					},
					p: {
						fontSize: '16px',
						fontStyle: 'normal',
						fontWeight: 400,
						lineHeight: '133%',
						marginTop: '0',
					},
				}}
			>
				<Progress></Progress>
			
			</Box>
		</Box>
	);
};
