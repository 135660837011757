import React from 'react';
import { Box } from '@mui/material';
import { ProgressHeader } from '../components/ProgressHeader';
import { Title } from '../components/commons/Title';
import { SubTitle } from '../components/commons/SubTitle';
import { PlanOptions } from '../components/PlanOptions';
import { InfoBox } from '../components/InfoBox';

export const Plan = ({ edit }) => {
	return (
		<Box>
			{!edit && (
				<ProgressHeader title="1. Planes dentales"></ProgressHeader>
			)}
			<Title
				variant="h1"
				sx={{
					color: '#283238',
					fontSize: '22px',
					marginTop: '10px',
				}}
			>
				¿Qué plan dental prefieres?
			</Title>
			<SubTitle
				sx={{
					color: '#283238',
					fontSize: '14px',
					marginTop: '16px',

					marginBottom: '38px',
				}}
			>
				Selecciona el plan que se adapte a tus necesidades
			</SubTitle>
			<PlanOptions />
			<InfoBox></InfoBox>
		</Box>
	);
};
