import React from 'react';
import { Box, Button } from '@mui/material';
import { Title } from '../components/commons/Title';
import { SubTitle } from '../components/commons/SubTitle';
import FolderList from './../components/ConnectedIcons.jsx';
import { btnStyle } from '../components/commons/styles.js';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useUserData } from '../state/atoms.js';

export const Home = ({ done }) => {
	const { serverData } = useUserData();

	return (
		<Box sx={{ paddingTop: '46px' }}>
			<Title variant="h1">
				¡Gracias por elegirnos para cuidar tu salud dental!
			</Title>
			<SubTitle sx={{ marginTop: '24px' }}>
				Ayúdanos con la siguiente información
			</SubTitle>
			<Box sx={{ padding: '56px 10px 120px 10px' }}>
				<FolderList done={done} />
			</Box>
			{done && (
				<Box sx={{ paddingBottom: '80px' }}>
					<Button
						variant="contained"
						sx={btnStyle}
						onClick={() => {
							window.location.href =
								serverData.link_payment +
								'&&redirection=' +
								serverData.redirection_id;
						}}
					>
						Ir a pagar{' '}
						<ArrowForwardIcon sx={{ marginLeft: '2px' }} />{' '}
					</Button>
				</Box>
			)}
		</Box>
	);
};
