import { Box } from '@mui/material';
import React from 'react';
import { Title } from './Title';
import { Link } from 'react-router-dom';
export const TitleWithEdit=({to,title})=>{
    return <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
    <Title sx={{}}>{title}</Title>
    <Link
        style={{
            display: 'flex',
            fontSize: '16px',
            alignItems: 'bottom',
            color:"#1973B8",
            gap: '8px',
        }}
        to={to}
    >
        Editar
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M12 3.5C13.6811 3.5 15.3245 3.99852 16.7224 4.93251C18.1202 5.8665 19.2096 7.19402 19.853 8.74719C20.4963 10.3004 20.6647 12.0094 20.3367 13.6583C20.0087 15.3071 19.1992 16.8217 18.0104 18.0104C16.8217 19.1992 15.3071 20.0087 13.6583 20.3367C12.0094 20.6646 10.3004 20.4963 8.74719 19.853C7.19402 19.2096 5.8665 18.1202 4.93251 16.7223C3.99852 15.3245 3.5 13.6811 3.5 12C3.50265 9.74647 4.39903 7.586 5.99252 5.99252C7.58601 4.39903 9.74648 3.50265 12 3.5ZM12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17316C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17316C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2Z"
                fill="#1973B8"
            />
            <path
                d="M8.10625 13.7724L7.75977 16.2401L10.2276 15.8937L14.4734 11.6479L12.352 9.52658L8.10625 13.7724Z"
                fill="#1973B8"
            />
            <path
                d="M15.1848 6.69379L13.7706 8.10801L15.8919 10.2293L17.3061 8.81511L15.1848 6.69379Z"
                fill="#1973B8"
            />
        </svg>
    </Link>
</Box>
}