import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

export const LinealProgess = withStyles(theme => ({
	root: {
		height: 10,
		borderRadius: 5,
	},
	colorPrimary: {
		backgroundColor:
			theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
	},
	bar: {
		borderRadius: 5,
		backgroundColor: '#5AC4C4',
		boxShadow: '4px 0px 5px 0px rgba(123, 42, 9, 0.50)',
	},
}))(LinearProgress);
